<div *ngIf="ticket$ | async as ticket" class="detailed-list-container">
  <div
    class="detailed-list-item"
    *ngFor="let workingPeriod of ticket.workPeriods; let i = index"
  >
    <div class="detailed-list-item-details">
      <mat-icon>work_history</mat-icon>
      <div class="info">
        <span class="title mat-body-1 color-grey-600">
          {{ workingPeriod.startingHour | date: 'd MMMM y' }}
        </span>
        <div class="subtitle">
          <crm-assignees-icon-list
            [assignees]="parseAssignees(workingPeriod)"
            [size]="18"
            [maxDisplay]="4"
          ></crm-assignees-icon-list>
          <span class="color-grey-400">
            <mat-icon class="color-grey-500">schedule</mat-icon>
            {{ formatTimeString(workingPeriod.startingHour) }}
            -
            {{ formatTimeString(workingPeriod.endingHour) }}
          </span>
        </div>
      </div>
    </div>
    <button
      class="d-flex justify-content-end delete-working-period"
      mat-icon-button
      (click)="handleDeleteWorkingPeriod(workingPeriod.id)"
      type="button"
    >
      <div class="icon red-icon">
        <fa-icon [icon]="faTrashCan"></fa-icon>
      </div>
    </button>
  </div>
</div>
<div class="add-period-container">
  <button mat-stroked-button type="button" color="primary" (click)="openForm()">
    <mat-icon>assignment_add</mat-icon> {{ 'add' | i18nextCap }}
  </button>
</div>
<ng-container *ngIf="workHistory$ | async as workHistory">
  <div *ngIf="workHistory.total > 0" class="working-period-worked">
    <ng-template
      ngFor
      let-assignee
      let-i="index"
      [ngForOf]="workHistory.assigneesWork"
    >
      <div class="working-period-worked-row">
        <span class="xlight">
          {{ assignee.firstName }} {{ assignee.lastName }}
        </span>
        <span class="semi-bold color-grey-700">
          {{ parseToTimeString(assignee.total) }}
        </span>
      </div>
      <mat-divider></mat-divider>
    </ng-template>
    <div class="working-period-worked-row total">
      <span class="bold">{{ 'total' | i18nextCap }}</span>
      <span class="bold">
        {{ parseToTimeString(workHistory.total) }}
      </span>
    </div>
  </div>
</ng-container>
