<div class="mode-bar">
  <button
    mat-icon-button
    [color]="currentView === 'dayGridMonth' ? 'primary' : 'default'"
    (click)="switchView('dayGridMonth')"
  >
    <mat-icon>calendar_view_month</mat-icon>
  </button>
  <button
    mat-icon-button
    [color]="currentView === 'timeGridWeek' ? 'primary' : 'default'"
    (click)="switchView('timeGridWeek')"
  >
    <mat-icon>calendar_view_week</mat-icon>
  </button>
  <button
    mat-icon-button
    [color]="currentView === 'timeGridDay' ? 'primary' : 'default'"
    (click)="switchView('timeGridDay')"
  >
    <mat-icon>calendar_view_day</mat-icon>
  </button>
  <button mat-button class="today-button" (click)="gotoToday()">
    <mat-icon>today</mat-icon> {{ 'today' | i18nextCap }}
  </button>
</div>
<div class="fill-space">
  <full-calendar [options]="options"> </full-calendar>
</div>
<div class="mode-bar">
  <button mat-icon-button (click)="fastBack()">
    <mat-icon>fast_rewind</mat-icon>
  </button>
  <button mat-icon-button (click)="back()">
    <mat-icon>skip_previous</mat-icon>
  </button>
  <span class="calendar-title">{{headerText}}</span>
  <button mat-icon-button (click)="forward()">
    <mat-icon>skip_next</mat-icon>
  </button>
  <button mat-icon-button (click)="fastForward()">
    <mat-icon>fast_forward</mat-icon>
  </button>
</div>
<div class="popup" #popup>
  <ng-container
    *ngIf="(hoveredEvent$ | async) != null"
    [ngTemplateOutlet]="popupTemplate"
    [ngTemplateOutletContext]="{$implicit: (hoveredEvent$ | async)}"
  >
  </ng-container>
</div>
