import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportRequestModalComponent } from '@fullyops/legacy/ui/ui-shared/report-request-modal/report-request-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  faFileSignature,
  faEnvelope,
  faEye,
  faEyeSlash,
  faRotate,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { ReportControllerControllerV2 } from '../ui-report-controller-v2.service';
import { I18NextService } from 'angular-i18next';
import { Report, ReportType } from '@fullyops/legacy/data/api/types/Report';
import { DialogDocumentViewComponent } from '@fullyops/legacy/ui/ui-shared/dialog-document-view/dialog.component';
import { UiCompaniesService } from '../ui-companies.service';
import { UiContactsService } from '../ui-contacts.service';
import { takeUntil } from 'rxjs/operators';
import { ReportListDialogComponent } from './report-list-dialog/report-list-dialog.component';
import { SaleTicketResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { TimeZoneDateFormatterService } from '@fullyops/shared/services/date-formatter.service';

@Component({
  selector: 'crm-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Input() ticketId: string;
  @Input() isDetailPage: boolean;
  @Input() reportType: ReportType;
  @Input() withProposal = false;
  @Input() reports$: BehaviorSubject<Report[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse | SaleTicketResponse>;

  actionLabel: string;

  faFileSignature = faFileSignature;
  faEnvelope = faEnvelope;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faRotate = faRotate;
  feExclamation = faExclamation;

  destroy$ = new Subject();

  constructor(
    public dialog: MatDialog,
    protected reportControllerControllerV2: ReportControllerControllerV2,
    protected companiesService: UiCompaniesService,
    protected contactsService: UiContactsService,
    public timezoneDate: TimeZoneDateFormatterService,
    protected i18next: I18NextService
  ) {}

  ngOnInit() {
    if (this.reportType == 'SALE_REPORT') {
      this.actionLabel = this.withProposal
        ? 'generateProposal'
        : 'signProposal';
    } else {
      this.actionLabel = this.withProposal ? 'generateReport' : 'signReport';
    }
  }

  getReportsSorted() {
    return this.reports$.value.sort((reportA, reportB) => {
      const reportACreatedAt = this.timezoneDate.formatInTimezone(
        reportA.createdAt
      );
      const reportBCreatedAt = this.timezoneDate.formatInTimezone(
        reportB.createdAt
      );
      return reportBCreatedAt.valueOf() - reportACreatedAt.valueOf();
    });
  }

  downloadReport({
    reportId,
    internal,
  }: {
    reportId: string;
    internal?: boolean;
  }) {
    this.reportControllerControllerV2
      .downloadReportAsURL({ reportId, internal })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dialog.open(DialogDocumentViewComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '90%',
          width: '90%',
          data: {
            document: res,
            filename: `report-${reportId}-${
              internal ? 'internal' : 'external'
            }.pdf`,
          },
        });
      });
  }

  onOpen({ reportId }: { reportId: string }) {
    this.downloadReport({ reportId });
  }

  onOpenInternal({ reportId }: { reportId: string }) {
    this.downloadReport({ reportId, internal: true });
  }

  generateProposal() {
    this.reportControllerControllerV2
      .generatePreview({
        reportType: this.reportType,
        ticketId: this.ticketId,
        language: this.i18next.language,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((report) => {
        const newReports = [...this.reports$.value];
        newReports.unshift(report);
        this.reports$.next(newReports);
      });
  }

  openReportListDialog(reportId) {
    this.dialog.open(ReportListDialogComponent, {
      data: { ticket$: this.ticket$, reportId, reportType: this.reportType },
    });
  }

  assignReport(ticketReport: Report, indexToUpdate: number) {
    const dialogRef = this.dialog.open(ReportRequestModalComponent);

    dialogRef.afterClosed().subscribe((file) => {
      if (file) {
        this.reportControllerControllerV2
          .handleSignatureUpload({
            file: file,
            reportType: ticketReport.type,
            reportId: ticketReport.id,
            language: this.i18next.language,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((responseReport) => {
            const newReports = [...this.reports$.value];
            newReports[indexToUpdate] = responseReport;
            this.reports$.next(newReports);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
